var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("breakdown-component", {
    attrs: {
      items: _vm.items,
      disabled: _vm.disabled,
      label: _vm.label,
      "hide-secondary": _vm.hideSecondary,
      "horizontal-grow": _vm.horizontalGrow,
    },
    on: { input: _vm.breakdownChanges, reset: _vm.emitReset },
    model: {
      value: _vm.breakdownValue,
      callback: function ($$v) {
        _vm.breakdownValue = $$v
      },
      expression: "breakdownValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
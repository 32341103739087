<template>
  <breakdown-component
    v-model="breakdownValue"
    :items="items"
    :disabled="disabled"
    :label="label"
    :hide-secondary="hideSecondary"
    :horizontal-grow="horizontalGrow"
    @input="breakdownChanges"
    @reset="emitReset"
  />
</template>

<script>
import Breakdown from '@/model/shared/Breakdown';
import BreakdownComponent from '@/components/molecules/modules/Analytics/Breakdown/Breakdown';
import { isValidBreakdown } from '@/utils/validation/isValidBreakdown';

export default {
  name: 'BreakdownWrapper',
  components: { BreakdownComponent },
  props: {
    label: { type: String, default: 'Select any item' },
    /** @type {{new () : Breakdown }} */
    items: { type: Object, validator: isValidBreakdown, required: true },
    disabled: { type: Boolean, default: false },
    /** @type Breakdown */
    value: { type: Object, default: () => new Breakdown() },
    hideSecondary: { type: Boolean, default: () => false },
    horizontalGrow: { type: Boolean, default: () => false },
  },
  data: () => ({
    breakdownValue: {},
    primaryIndex: null,
    secondaryIndex: null,
  }),
  watch: {
    value: {
      deep: true,
      handler: 'setValues',
    },
  },
  mounted() {
    this.setValues();
  },
  methods: {
    setValues() {
      this.primaryIndex = null;
      this.secondaryIndex = null;

      this.primaryIndex = this.items.items.findIndex(breakdown => breakdown.value === this.value.primary);
      const breakdown = this.items.items[this.primaryIndex];
      this.secondaryIndex = breakdown.items?.items.findIndex(
        secondaryBreakdown => secondaryBreakdown.value === this.value.secondary
      );

      if (!this.value.secondary) {
        this.secondaryIndex = undefined;
      }
      this.breakdownValue = {
        primary: this.value.primary,
        secondary: this.value.secondary,
        index: this.primaryIndex,
        subIndex: this.secondaryIndex,
      };
    },
    breakdownChanges(event) {
      const toEmit = {
        primary: this.items.items[event.index].value,
        secondary: this.items.items[event.index].items?.items[event.subIndex]?.value || null,
      };
      this.$emit('input', toEmit);
    },
    emitReset() {
      this.$emit('reset');
    },
  },
};
</script>

export default {
  methods: {
    // indicate how must render the row with childs.
    customAggFunc(params) {
      if (!this.primaryRowCache.length) {
        return params.values[0];
      }
      const row = this.primaryRowCache.find(
        cacheRow => cacheRow[this.primaryBreakdownCol.field] === params.rowNode.key
      );
      return row ? row[params.colDef.field] : params.values[0];
    },
  },
};
